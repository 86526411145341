import React from 'react'
import { Link, graphql } from 'gatsby'
import Seo from '../components/seo'
import Header from '../components/header'
import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'
import Footer from '../components/footer'
import Copyright from '../components/copyright'
import NavMobile from "../components/nav_mobile"

function sortChecks (data, section) {
    let result = [];
	for (let entry of data.edges) {
        if (entry.node.frontmatter.section === section) {
            result.push(entry.node)
        }
    }
    result.sort(function(a, b) {
        return a.frontmatter.nav_order - b.frontmatter.nav_order;
    });
	return result;
}

const GetChecks = (p) => <div className="grid grid__3 grid__gap--24">
{p.data.map( (entry) => (
    <div className="top__checks">
        <Link key={entry.id} to={entry.fields.slug}>
            <figure>
                <img src={`/img/${entry.frontmatter.icon}.svg`} alt={entry.frontmatter.title} />
            </figure>
            <h2>{entry.frontmatter.title} &rsaquo;</h2>
            <p>{entry.frontmatter.excerpt}</p>
            <p><strong>Duration: {entry.frontmatter.turnaround}</strong></p>
        </Link>
    </div>
) )}
</div>

export default ({ data }) => {
    const entry = data.markdownRemark
    //console.log('data.allMarkdownRemark.edges: ', data.allMarkdownRemark.edges);
    return (
        <div className="page">
            <NavMobile />
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <Header />
            <BackgroundImage className="layout title__48" fluid={entry.frontmatter.background.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                <div className="layout__container">
                    <div className="layout__title banner center">
                        <h1>{entry.frontmatter.heading}</h1>
                    </div>
                </div>
            </BackgroundImage>
            <div className="layout">
                <div className="layout__container">
                    <div className="layout__wide">
                        <nav className="breadcrumb breadcrumb__top">
                            <p><Link to="/">Home</Link> &nbsp;&rsaquo;&nbsp; <strong>Background checks</strong></p>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="layout">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="body">
                            <GetChecks data={sortChecks(data.allMarkdownRemark, 'checks')} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="layout title__72 dark__blue">
                <div className="layout__container">
                    <div className="layout__wide title white">
                        <div className="grid grid__2 grid__gap--48 align__items">
                            <figure>
                                <Img fluid={entry.frontmatter.intro.image.childImageSharp.fluid} alt={entry.frontmatter.intro.heading} />
                            </figure>
                            <div className="title__block">
                                <h2>{entry.frontmatter.intro.heading}</h2>
                                <p>{entry.frontmatter.intro.text}</p>
                                <Link to="/contact/" className="btn btn__margin__small btn__blue btn__small">Make enquiry &rsaquo;</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <Copyright />
        </div>
    )
}

export const check_list = graphql`
    query($slug: String!) {
        markdownRemark( fields: { slug: { eq: $slug } }) {
            frontmatter {
                title
                page_title
                meta_description
                background {
                    childImageSharp {
                        fluid(maxWidth: 1024) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                heading
                intro {
                    heading
                    text
                    image {
                        childImageSharp {
                            fluid(maxWidth: 800) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            fields {
                slug
            }
            html
        }
        allMarkdownRemark(filter: {frontmatter: {section: {eq: "checks"}}}) {
            edges {
                node {
                    frontmatter {
                        title
                        section
                        nav_order
                        turnaround
                        heading
                        excerpt
                        icon
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`